<template>
  <div>
    <v-row v-if="!loading">
      <v-col md="12" v-if="vendorLocationsGridShow">
        <v-card elevation="10">
          <v-toolbar
            dense
            color="#003d6a"
            elevation="0"
            dark
            style="z-index: 9"
          >
            <v-toolbar-title>Firma Lokasyonları</v-toolbar-title>
            <v-spacer></v-spacer>
            <div class="d-flex justify-content-end" style="min-width: 550px">
              <input
                type="text"
                v-model="searchFilterInput"
                class="form-control mr-3"
                style="max-width: 250px"
                placeholder="Arama..."
                v-on:keyup="onFilterTextBoxChanged()"
              />
              <RoleProvider slug="VENDOR_LOCATION_CREATE">
                <div slot-scope="{ canItPass }">
                  <CButton
                    color="light"
                    variant="outline"
                    size="sm"
                    v-if="canItPass"
                    @click="
                      cleanVendorLocationModalAddUpdateForm(),
                        (vendorLocationModalAddUpdateOptions.process = 'post'),
                        (vendorLocationModalAddUpdateOptions.title =
                          'Firma Lokasyonu Ekle'),
                        (vendorLocationModalAddUpdate = true)
                    "
                    class="py-2 px-3 font-weight-bold"
                  >
                    <font-awesome-icon
                      :icon="['fas', 'plus']"
                      class="mr-2"
                    />Yeni Ekle
                  </CButton>
                </div>
              </RoleProvider>
            </div>
          </v-toolbar>

          <CCardBody class="p-0">
            <ag-grid-vue
              style="width: 100%; height: calc(100vh - 145px)"
              class="ag-theme-balham"
              :columnDefs="columnDefs"
              :defaultColDef="defaultColDef"
              :rowData="rowData"
              :sideBar="sideBar"
              :rowSelection="rowSelection"
              :enableRangeSelection="true"
              :statusBar="statusBar"
              @rowDoubleClicked="onRowDoubleClicked"
              @grid-ready="onGridReady"
              :getContextMenuItems="getContextMenuItems"
            ></ag-grid-vue>
          </CCardBody>
        </v-card>
      </v-col>
    </v-row>
    <div class="w-100" v-else>
      <gogo-loading />
    </div>
    <CModal
      :title="vendorLocationModalAddUpdateOptions.title"
      size="xl"
      :closeOnBackdrop="false"
      :show.sync="vendorLocationModalAddUpdate"
      class="vendorLocationModalAddUpdateForm modalNoBorder"
    >
      <CCard class="p-0 m-0 cardShadow">
        <CCardBody>
          <CContainer class="p-0 m-0">
            <CRow>
              <CCol md="12" lg="4">
                <CSelect
                  label="Firma"
                  :value.sync="vendorLocationModalAddUpdateForm.vendorSlug"
                  :options="vendorLocationModalAddUpdateFormVendorSlugOptions"
                />
              </CCol>
              <CCol md="12" lg="4">
                <CSelect
                  label="Durum"
                  :value.sync="vendorLocationModalAddUpdateForm.status"
                  :options="vendorLocationModalAddUpdateFormStatusOptions"
                />
              </CCol>
              <CCol md="12" lg="4">
                <CInput
                  label="Lokasyon Adı"
                  v-model="vendorLocationModalAddUpdateForm.vendorLocationName"
                />
              </CCol>
              <CCol md="12" lg="4">
                <CInput
                  label="Lokasyon Kodu"
                  v-model="vendorLocationModalAddUpdateForm.vendorLocationCode"
                />
              </CCol>
              <CCol md="12" lg="4">
                <CInput
                  label="E-Posta"
                  v-model="vendorLocationModalAddUpdateForm.email"
                />
              </CCol>
              <CCol md="12" lg="4">
                <CInput
                  label="Telefon"
                  v-model="vendorLocationModalAddUpdateForm.telephone"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12" lg="4">
                <CRow>
                  <CCol>
                    <CInput
                      label="Şehir"
                      v-model="vendorLocationModalAddUpdateForm.city"
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol>
                    <CInput
                      label="Ülke Kodu"
                      v-model="vendorLocationModalAddUpdateForm.countryCode"
                    />
                  </CCol>
                </CRow>
              </CCol>
              <CCol md="12" lg="8">
                <CTextarea
                  label="Lokasyon Adres"
                  rows="5"
                  :resize="false"
                  v-model="vendorLocationModalAddUpdateForm.address"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12" lg="6">
                <CSelect
                  label="Teslim Yeri"
                  :value.sync="vendorLocationModalAddUpdateForm.deliveryType"
                  :options="vendorLocationModalAddUpdateFormDeliveryTypeOptions"
                />
              </CCol>
              <CCol md="12" lg="6">
                <label class>Eşlenecek Lokasyon</label>
                <v-autocomplete
                  class="mt-2"
                  v-model="vendorLocationModalAddUpdateForm.locationSlug"
                  :items="vendorLocationModalAddUpdateFormLocationSlugOptions"
                  outlined
                  dense
                  item-text="label"
                ></v-autocomplete>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12" lg="6">
                <CInputCheckbox
                  id="flexCheckDefault"
                  label="Biniş Kartı Zorunluluğu Var"
                  @click="
                    vendorLocationModalAddUpdateForm.boardingpass =
                      !vendorLocationModalAddUpdateForm.boardingpass
                  "
                  :checked="vendorLocationModalAddUpdateForm.boardingpass"
                />
              </CCol>
            </CRow>
          </CContainer>
        </CCardBody>
      </CCard>
      <template #footer>
        <RoleProvider slug="VENDOR_LOCATION_CREATE">
          <div slot-scope="{ canItPass }">
            <CButton
              color="success"
              v-if="
                vendorLocationModalAddUpdateOptions.process == 'post' &&
                canItPass
              "
              :disabled="vendorLocationModalAddUpdateBtnDisable"
              @click="vendorLocationAddUpdate()"
              >Ekle</CButton
            >
          </div>
        </RoleProvider>
        <RoleProvider slug="VENDOR_LOCATION_DELETE">
          <div slot-scope="{ canItPass }">
            <CButton
              color="danger"
              v-if="
                vendorLocationModalAddUpdateOptions.process == 'put' &&
                canItPass
              "
              @click="deleted(vendorLocationModalAddUpdateForm._id)"
              >Sil</CButton
            >
          </div>
        </RoleProvider>
        <RoleProvider slug="VENDOR_LOCATION_UPDATE">
          <div slot-scope="{ canItPass }">
            <CButton
              color="success"
              v-if="
                vendorLocationModalAddUpdateOptions.process == 'put' &&
                canItPass
              "
              :disabled="vendorLocationModalAddUpdateBtnDisable"
              @click="vendorLocationAddUpdate()"
              >Düzenle</CButton
            >
          </div>
        </RoleProvider>
      </template>
    </CModal>

    <CModal
      title="Toplu Güncelleme"
      size="xl"
      :closeOnBackdrop="false"
      :show.sync="pairingModalStatus"
      class="pairingModal modalNoBorder"
    >
      <CCard class="p-0 m-0 cardShadow">
        <CCardBody>
          <CContainer class="p-0 m-0">
            <CRow>
              <CCol md="12" lg="12">
                <CAlert show color="info">
                  Seçtiğiniz
                  <strong>{{ pairingForm.locationIds.length }} lokasyon</strong>
                  toplu olarak güncellenecek!
                </CAlert>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12" lg="2">
                <CSelect
                  label="Durum"
                  :value.sync="pairingForm.status"
                  :options="vendorLocationModalAddUpdateFormStatusOptions"
                />
              </CCol>
            </CRow>
          </CContainer>
        </CCardBody>
      </CCard>
      <template #footer>
        <CButton
          color="success"
          :disabled="vendorLocationModalAddUpdateBtnDisable"
          @click="vendorLocationPairing()"
          >Güncelle</CButton
        >
      </template>
    </CModal>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import Func from "../../func";
import { AgGridVue } from "ag-grid-vue";
import VueSweetalert2 from "vue-sweetalert2";
import vSelect from "vue-select";
import { RoleProvider } from "../../provider";
import GogoLoading from "../../components/GogoLoading.vue";
import "vue-select/dist/vue-select.css";
var _ = require("lodash");
axios.defaults.withCredentials = true;
Vue.use(VueSweetalert2);

export default {
  name: "VendorLocationSearch",
  created() {
    // if (this.$route.params.vendorSlug != undefined) {
    // 	this.locationForm.carBrand = this.$route.params.vendorSlug;
    // 	this.locationSearch();
    // }

    this.getVendorList();
    this.getLocationList();
    this.searchVendorLocation();
    this.lastFilter();
  },
  data() {
    return {
      pairingModalStatus: false,
      searchFilterInput: "",
      vendorLocationsGridShow: false,
      columnDefs: [
        {
          field: "status",
          cellRenderer: (params) => {
            return params.value ? "Aktif" : "Pasif";
          },
          cellStyle: (params) => {
            return params.value
              ? { color: "#2eb85c", fontWeight: "bold" }
              : { color: "#e55353", fontWeight: "bold" };
          },
          headerName: "Durum",
          checkboxSelection: true,
          headerCheckboxSelection: true,
          width: 110,
        },
        {
          field: "vendorDetail.brand",
          headerName: "Firma",
          width: 150,
        },

        {
          field: "vendorLocationName",
          tooltipField: "vendorLocationName",
          headerName: "Lokasyon Adı",
          width: 300,
        },
        {
          field: "locationDetail.locationName",
          cellRenderer: (params) => {
            if (params.value) return params.value;
            else return "Lokasyon eşleştirilmemiş!";
          },
          cellStyle: (params) => {
            if (!params.value) return { color: "#e55353", fontWeight: "bold" };
          },
          tooltipField: "locationDetail.locationName",
          headerName: "Eşleştirilen Lokasyon Adı",
          width: 300,
        },
        {
          field: "locationSlug",
          headerName: "Lokasyon Kodu",
          width: 150,
        },
        {
          field: "city",
          cellRenderer: (params) => {
            return Func.strOnlyFirstCharUpperCase(params.value);
          },
          headerName: "Şehir",
          width: 120,
        },
        {
          field: "address",
          cellRenderer: (params) => {
            return Func.strInitialsUpperCase(params.value);
          },
          tooltipField: "address",
          headerName: "Adres",
          width: 250,
        },
        {
          field: "deliveryType",
          cellRenderer: (params) => {
            if (params.value === "centralOffice") return "Merkez Ofis";
            else if (params.value === "inTerminal") return "Terminal İçi";
            else if (params.value === "welcoming") return "Karşılama";
            else if (params.value === "freeShuttle") return "Ücretsiz Servis";
            else if (params.value === "vale") return "Vale";
            else return "-";
          },
          headerName: "Teslim Tipi",
          width: 150,
        },

        {
          field: "email",
          headerName: "Email",
          width: 190,
        },
        {
          field: "telephone",

          headerName: "Telefon",
          width: 130,
        },
      ],
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true,
        filterParams: {
          newRowsAction: "keep",
        },
      },
      rowData: [],
      sideBar: {
        // Grid yan çubuk tanımlaması
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Sütunlar",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          ,
          {
            id: "filters",
            labelDefault: "Filtreler",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
        defaultToolPanel: "",
      },
      rowSelection: "multiple", // Satır Seçim Türü
      statusBar: {
        // Durum çubuğunda kullanılacak durum çubuğu bileşenlerini belirtir.
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left",
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center",
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" },
        ],
      },
      // FİRMA LOKASYONU ARAMA
      searchVendorLocationFormCollapsed: false,
      searchVendorLocationFormStatusOptions: [
        {
          value: "",
          label: "Tümü",
        },
        {
          value: true,
          label: "Aktif",
        },
        {
          value: false,
          label: "Pasif",
        },
      ],
      searchVendorLocationFormVendorSlugOptions: [
        {
          value: "",
          label: "Tümü",
        },
      ],
      searchVendorLocationFormLocationSlugOptions: [
        {
          value: "",
          label: "Tümü",
        },
      ],
      searchVendorLocationFormDeliveryTypeOptions: [
        {
          value: "",
          label: "Tümü",
        },
        {
          value: "centralOffice",
          label: "Merkez Ofis",
        },
        {
          value: "inTerminal",
          label: "Terminal İçi",
        },
        {
          value: "welcoming",
          label: "Karşılama",
        },
        {
          value: "freeShuttle",
          label: "Ücretsiz Servis",
        },
        {
          value: "vale",
          label: "Vale ",
        },
      ],
      searchVendorLocationForm: {
        status: "",
        vendorSlug: "",
        locationSlug: "",
        vendorLocationCode: "",
        countryCode: "",
        city: "",
        deliveryType: "",
      },
      // FİRMA LOKASYONU EKLEME GÜNCELLEME MODAL
      vendorLocationModalAddUpdate: false, // VendorLocation Modal Add Update
      vendorLocationModalAddUpdateBtnDisable: false, // VendorLocation Modal Add Update button disabled enabled değişkeni
      vendorLocationModalAddUpdateOptions: {
        // VendorLocation Modal Add Update değişkenleri
        process: "",
        title: "",
        shake: {
          vendorLocationName: false,
          vendorLocationCode: false,
          city: false,
          countryCode: false,
          address: false,
          email: false,
          telephone: false,
        },
      },
      vendorLocationModalAddUpdateFormStatusOptions: [
        {
          value: true,
          label: "Aktif",
        },
        {
          value: false,
          label: "Pasif",
        },
      ],
      vendorLocationModalAddUpdateFormVendorSlugOptions: [],
      vendorLocationModalAddUpdateFormDeliveryTypeOptions: [
        {
          value: "centralOffice",
          label: "Merkez Ofis",
        },
        {
          value: "inTerminal",
          label: "Terminal İçi",
        },
        {
          value: "welcoming",
          label: "Karşılama",
        },
        {
          value: "freeShuttle",
          label: "Ücretsiz Servis",
        },
        {
          value: "vale",
          label: "Vale ",
        },
      ],
      vendorLocationModalAddUpdateFormLocationSlugOptions: [],
      vendorLocationModalAddUpdateFormErrors: {
        vendorLocationName: "",
        vendorLocationCode: "",
        city: "",
        countryCode: "",
        address: "",
        email: "",
        telephone: "",
      },
      vendorLocationModalAddUpdateForm: {
        _id: "",
        status: false,
        vendorLocationName: "",
        vendorLocationCode: "",
        city: "",
        countryCode: "",
        address: "",
        vendorSlug: "",
        email: "",
        telephone: "",
        deliveryType: "",
        locationSlug: "",
        boardingpass: false,
      },
      pairingForm: {
        status: true,
        locationSlug: "",
        locationIds: [],
      },
      loading: false,
    };
  },
  components: {
    AgGridVue,
    vSelect,
    RoleProvider,
    GogoLoading,
  },
  watch: {
    "vendorLocationModalAddUpdateForm.brand": function () {
      if (this.vendorLocationModalAddUpdateForm.brand != "")
        this.vendorLocationModalAddUpdateForm.brandSlug = Func.slugify(
          this.vendorLocationModalAddUpdateForm.brand
        );
      else this.vendorLocationModalAddUpdateForm.brandSlug = "";
    },
  },
  methods: {
    lastFilter(){
      this.searchFilterInput = JSON.parse(localStorage.getItem('VendorslastFilter') || '{}').VendorLocationPageFilter || '';
    },
    getContextMenuItems(params) {
      var _this = this;
      var selectedRowCount = params.api.getSelectedRows().length;
      var result = [
        {
          name: "Güncelle (" + selectedRowCount + " Lokasyon)",
          disabled: selectedRowCount === 0 ? true : false,
          action: function () {
            var selectedRows = params.api.getSelectedRows();
            _this.pairingForm.locationIds = [];
            for (const rowId of selectedRows) {
              _this.pairingForm.locationIds.push(rowId._id);
            }
            _this.pairingModalStatus = true;
          },
          cssClasses: ["redFont", "bold"],
        },
        "separator",
        "copy",
        "export",
      ];
      return result;
    },
    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(this.searchFilterInput);
      localStorage.setItem('VendorslastFilter', JSON.stringify({VendorLocationPageFilter: this.searchFilterInput}));

},
    onGridReady(params) {
      // Grid ready olduğunda
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
    getGridContextMenuItems(params) {
      var _this = this;
      var result = [
        {
          name: "Sil",
          action: function () {
            Vue.swal({
              icon: "error",
              title: "Lokasyon Sil",
              html:
                "<strong>" +
                params.node.data.vendorDetail.brand +
                "</strong> isimli firmaya ait <strong>" +
                params.node.data.vendorLocationName +
                "</strong> lokasyonunu silmek istiyor musunuz?",
              showDenyButton: true,
              confirmButtonText: "Evet",
              denyButtonText: "Hayır",
            }).then((result) => {
              if (result.isConfirmed)
                _this.vendorLocationDelete({ _id: params.node.data._id });
            });
          },
        },
        {
          name: "Düzenle",
          action: function () {
            _this.cleanVendorLocationModalAddUpdateForm();
            _this.vendorLocationModalAddUpdateOptions.process = "put";
            _this.vendorLocationModalAddUpdateOptions.title =
              "Lokasyon Güncelle";

            _this.vendorLocationModalAddUpdateForm._id = params.node.data._id;
            _this.vendorLocationModalAddUpdateForm.status =
              params.node.data.status;
            _this.vendorLocationModalAddUpdateForm.vendorLocationName =
              params.node.data.vendorLocationName;
            _this.vendorLocationModalAddUpdateForm.vendorLocationCode =
              params.node.data.vendorLocationCode;
            _this.vendorLocationModalAddUpdateForm.city = params.node.data.city;
            _this.vendorLocationModalAddUpdateForm.countryCode =
              params.node.data.countryCode;
            _this.vendorLocationModalAddUpdateForm.address =
              params.node.data.address;
            _this.vendorLocationModalAddUpdateForm.vendorSlug =
              params.node.data.vendorSlug;
            _this.vendorLocationModalAddUpdateForm.email =
              params.node.data.email;
            _this.vendorLocationModalAddUpdateForm.telephone =
              params.node.data.telephone;
            _this.vendorLocationModalAddUpdateForm.deliveryType =
              params.node.data.deliveryType;
            _this.vendorLocationModalAddUpdateForm.locationSlug =
              params.node.data.locationSlug;

            _this.vendorLocationModalAddUpdate = true;
          },
        },
        {
          name: "Kopyala",
          action: function () {
            _this.gridApi.copySelectedRangeToClipboard();
          },
        },
        "separator",
        "chartRange",
      ];
      return result;
    },
    validVendorLocationName(val) {
      if (val) {
        if (val.toString().length > 50) {
          this.vendorLocationModalAddUpdateFormErrors.vendorLocationName =
            "Bu alana en fazla 50 karakter girilebilir!";
          return false;
        } else {
          this.vendorLocationModalAddUpdateFormErrors.vendorLocationName = "";
          return true;
        }
      } else {
        this.vendorLocationModalAddUpdateFormErrors.vendorLocationName = "";
        //"Bu alan boş bırakılamaz!";
        return false;
      }
    },
    validVendorLocationCode(val) {
      if (val) {
        if (val.toString().length > 30) {
          this.vendorLocationModalAddUpdateFormErrors.vendorLocationCode =
            "Bu alana en fazla 30 karakter girilebilir!";
          return false;
        } else {
          this.vendorLocationModalAddUpdateFormErrors.vendorLocationCode = "";
          return true;
        }
      } else {
        this.vendorLocationModalAddUpdateFormErrors.vendorLocationCode = "";
        //"Bu alan boş bırakılamaz!";
        return false;
      }
    },
    validCity(val) {
      if (val) {
        if (val.toString().length > 30) {
          this.vendorLocationModalAddUpdateFormErrors.city =
            "Bu alana en fazla 30 karakter girilebilir!";
          return false;
        } else {
          this.vendorLocationModalAddUpdateFormErrors.city = "";
          return true;
        }
      } else {
        this.vendorLocationModalAddUpdateFormErrors.city = "";
        //"Bu alan boş bırakılamaz!";
        return false;
      }
    },
    validCountryCode(val) {
      if (val) {
        if (val.toString().length > 10) {
          this.vendorLocationModalAddUpdateFormErrors.countryCode =
            "Bu alana en fazla 10 karakter girilebilir!";
          return false;
        } else {
          this.vendorLocationModalAddUpdateFormErrors.countryCode = "";
          return true;
        }
      } else {
        this.vendorLocationModalAddUpdateFormErrors.countryCode = "";
        //"Bu alan boş bırakılamaz!";
        return false;
      }
    },
    validAddress(val) {
      if (val) {
        if (val.toString().length > 200) {
          this.vendorLocationModalAddUpdateFormErrors.address =
            "Bu alana en fazla 200 karakter girilebilir!";
          return false;
        } else {
          this.vendorLocationModalAddUpdateFormErrors.address = "";
          return true;
        }
      } else {
        this.vendorLocationModalAddUpdateFormErrors.address = "";
        //"Bu alan boş bırakılamaz!";
        return false;
      }
    },
    validEmail(val) {
      if (val) {
        if (val.toString().length > 50) {
          this.vendorLocationModalAddUpdateFormErrors.email =
            "Bu alana en fazla 50 karakter girilebilir!";
          return false;
        } else {
          this.vendorLocationModalAddUpdateFormErrors.email = "";
          return true;
        }
      } else {
        this.vendorLocationModalAddUpdateFormErrors.email = "";
        //"Bu alan boş bırakılamaz!";
        return false;
      }
    },
    validTelephone(val) {
      if (val) {
        if (val.toString().length > 25) {
          this.vendorLocationModalAddUpdateFormErrors.telephone =
            "Bu alana en fazla 25 karakter girilebilir!";
          return false;
        } else {
          this.vendorLocationModalAddUpdateFormErrors.telephone = "";
          return true;
        }
      } else {
        this.vendorLocationModalAddUpdateFormErrors.telephone = "";
        //"Bu alan boş bırakılamaz!";
        return false;
      }
    },
    cleanVendorLocationModalAddUpdateForm() {
      this.vendorLocationModalAddUpdateForm._id = "";
      this.vendorLocationModalAddUpdateForm.status =
        this.vendorLocationModalAddUpdateFormStatusOptions[0]?.value;
      this.vendorLocationModalAddUpdateForm.vendorLocationName = "";
      this.vendorLocationModalAddUpdateForm.vendorLocationCode = "";
      this.vendorLocationModalAddUpdateForm.city = "";
      this.vendorLocationModalAddUpdateForm.countryCode = "";
      this.vendorLocationModalAddUpdateForm.address = "";
      this.vendorLocationModalAddUpdateForm.vendorSlug =
        this.vendorLocationModalAddUpdateFormVendorSlugOptions[0]?.value;
      this.vendorLocationModalAddUpdateForm.email = "";
      this.vendorLocationModalAddUpdateForm.telephone = "";
      this.vendorLocationModalAddUpdateForm.deliveryType =
        this.vendorLocationModalAddUpdateFormDeliveryTypeOptions[0]?.value;
      this.vendorLocationModalAddUpdateForm.locationSlug =
        this.vendorLocationModalAddUpdateFormLocationSlugOptions[0]?.value;
    },
    getVendorList() {
      var _this = this;

      axios
        .get(process.env.VUE_APP_API_URL + "admin/vendor")
        .then((response) => {
          if (response.data.vendorList.length > 0) {
            response.data.vendorList.map(function (result) {
              _this.searchVendorLocationFormVendorSlugOptions.push({
                label: result.brand,
                value: result.brandSlug,
              });

              _this.vendorLocationModalAddUpdateFormVendorSlugOptions.push({
                label: result.brand,
                value: result.brandSlug,
              });
            });
          }
        });
    },
    getLocationList() {
      var _this = this;

      axios
        .get(process.env.VUE_APP_API_URL + "admin/location?status=true")
        .then((response) => {
          if (response.data.locationList.length > 0) {
            response.data.locationList.map(function (result) {
              _this.searchVendorLocationFormLocationSlugOptions.push({
                label: result.locationName,
                value: result.slug,
              });

              _this.vendorLocationModalAddUpdateFormLocationSlugOptions.push({
                label: result.locationName,
                value: result.slug,
              });
            });
          }
        });
    },
    searchVendorLocation() {
      this.$Progress.start();
      let params = this.searchVendorLocationForm,
        existQuery = this.$route?.query?.existQuery;
      if (existQuery) params.existQuery = existQuery;
      axios
        .get(process.env.VUE_APP_API_URL + "admin/vendor/locations", {
          params,
        })
        .then((response) => {
          this.rowData = _.reverse(response.data.vendorLocationList);
          this.searchVendorLocationFormCollapsed = false;
          this.vendorLocationsGridShow = true;
          this.$Progress.finish();
        });
    },
    vendorLocationAddUpdate() {
      Func.submitValidController(
        this.vendorLocationModalAddUpdateFormErrors,
        this.vendorLocationModalAddUpdateOptions.shake,
        (result) => {
          if (result) {
            // true ise form validation hatası yok
            this.$Progress.start(); // Progress true ise gösteriyoruz
            this.vendorLocationModalAddUpdateBtnDisable = true; // İşlemi yaptıran button'u disable ettik.
            axios[this.vendorLocationModalAddUpdateOptions.process](
              process.env.VUE_APP_API_URL + "admin/vendor/locations",
              this.vendorLocationModalAddUpdateForm,
              { withCredentials: true }
            ).then((response) => {
              this.$Progress.finish(); // Progress true ise gizliyoruz
              this.vendorLocationModalAddUpdateBtnDisable = false; // İşlemi yaptıran button'u enable ettik.

              if (response.data.result === "success") {
                if (this.vendorLocationsGridShow) this.searchVendorLocation(); // AG Grid açıksa (Yani daha önce arama yapıldıysa) son aramayı yeniden yap
                this.vendorLocationModalAddUpdate = false; // Modal'ı kapattık
              }

              Vue.swal({
                icon: response.data.result,
                title: this.vendorLocationModalAddUpdateOptions.title,
                html: response.data.message,
                confirmButtonText: "Tamam",
              });
            });
          }
        }
      );
    },
    vendorLocationPairing() {
      // true ise form validation hatası yok
      this.$Progress.start(); // Progress true ise gösteriyoruz
      this.vendorLocationModalAddUpdateBtnDisable = true; // İşlemi yaptıran button'u disable ettik.

      axios
        .put(
          process.env.VUE_APP_API_URL + "admin/vendor/locations/pairing",
          this.pairingForm,
          { withCredentials: true }
        )
        .then((response) => {
          this.$Progress.finish(); // Progress true ise gizliyoruz
          this.vendorLocationModalAddUpdateBtnDisable = false; // İşlemi yaptıran button'u enable ettik.

          if (response.data.result === "success") {
            if (this.vendorLocationsGridShow) this.searchVendorLocation(); // AG Grid açıksa (Yani daha önce arama yapıldıysa) son aramayı yeniden yap
            this.pairingModalStatus = false; // Modal'ı kapattık
          }

          Vue.swal({
            icon: response.data.result,
            title: "Toplu Eşleştirme",
            html: response.data.message,
            confirmButtonText: "Tamam",
          });
        });
    },

    deleted(id) {
      Vue.swal
        .fire({
          title: "Uyarı ?",
          text: "Bu lokasyonu silmek istediğinize emin misiniz?",
          icon: "question",
          showCancelButton: true,
          showCloseButton: true,
          confirmButtonText: "Evet",
          cancelButtonText: "Hayır",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$Progress.start();
            try {
              axios.delete(
                process.env.VUE_APP_API_URL + "admin/vendor/locations/" + id,
                { withCredentials: true }
              );
              this.$Progress.finish();

              this.$store.dispatch({
                type: "successSwal",
                text: "Lokasyon başarılı bir şekilde silindi",
                refresh: true,
              });
            } catch (error) {
              Vue.swal.fire({
                icon: "error",
                showCloseButton: true,
                text: error.response.data.message,
              });
            }
          }
        });
    },
    onRowDoubleClicked(params) {
      var _this = this;
      _this.cleanVendorLocationModalAddUpdateForm();
      _this.vendorLocationModalAddUpdateOptions.process = "put";
      _this.vendorLocationModalAddUpdateOptions.title =
        "Firma Lokasyonu Güncelle";

      _this.vendorLocationModalAddUpdateForm._id = params.node.data._id;
      _this.vendorLocationModalAddUpdateForm.status = params.node.data.status;
      _this.vendorLocationModalAddUpdateForm.vendorLocationName =
        params.node.data.vendorLocationName;
      _this.vendorLocationModalAddUpdateForm.vendorLocationCode =
        params.node.data.vendorLocationCode;
      _this.vendorLocationModalAddUpdateForm.city = params.node.data.city;
      _this.vendorLocationModalAddUpdateForm.countryCode =
        params.node.data.countryCode;
      _this.vendorLocationModalAddUpdateForm.address = params.node.data.address;
      _this.vendorLocationModalAddUpdateForm.vendorSlug =
        params.node.data.vendorSlug;
      _this.vendorLocationModalAddUpdateForm.email = params.node.data.email;
      _this.vendorLocationModalAddUpdateForm.telephone =
        params.node.data.telephone;
      _this.vendorLocationModalAddUpdateForm.deliveryType =
        params.node.data.deliveryType;
      _this.vendorLocationModalAddUpdateForm.locationSlug =
        params.node.data.locationSlug;
      _this.vendorLocationModalAddUpdateForm.boardingpass =
        params.node.data.boardingPassRequirement;

      _this.vendorLocationModalAddUpdate = true;
    },
  },
};
</script>
